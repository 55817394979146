import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.css';

function NotFound() {
  return (
    <div className="not-found">
      <div className="content">
        <h1>404</h1>
        <p className="message">Page not found</p>
        <Link to="/" className="home-link">Return Home</Link>
      </div>
      
      {/* Background animation matching home page */}
      <svg className="background-effect" width="100%" height="100%" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        filter: 'blur(100px)',
        opacity: 0.7,
      }}>
        <defs>
          <linearGradient id="lavaGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#2E5266">
              <animate 
                attributeName="stop-color" 
                values="#2E5266; #6E8898; #9FB1BC; #D3D0CB; #2E5266" 
                dur="15s" 
                repeatCount="indefinite"/>
            </stop>
            <stop offset="100%" stopColor="#6E8898">
              <animate 
                attributeName="stop-color" 
                values="#6E8898; #9FB1BC; #D3D0CB; #2E5266; #6E8898" 
                dur="15s" 
                repeatCount="indefinite"/>
            </stop>
          </linearGradient>
        </defs>
        <circle cx="30%" cy="30%" r="60" fill="url(#lavaGradient)">
          <animate attributeName="cx" values="30%;70%;30%" dur="20s" repeatCount="indefinite" />
          <animate attributeName="cy" values="30%;70%;30%" dur="23s" repeatCount="indefinite" />
        </circle>
        <circle cx="70%" cy="70%" r="60" fill="url(#lavaGradient)">
          <animate attributeName="cx" values="70%;30%;70%" dur="23s" repeatCount="indefinite" />
          <animate attributeName="cy" values="70%;30%;70%" dur="20s" repeatCount="indefinite" />
        </circle>
      </svg>
    </div>
  );
}

export default NotFound;