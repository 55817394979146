import React from 'react';
import '../styles/PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <div className="content">
        <h1>SMS Privacy Policy</h1>
        
        <section>
          <h2>Third Parties that Help Provide the Messaging Service</h2>
          <p>Ex Nihilo Management LLC may disclose Personal Data and other information as follows:</p>
          <p>We will not share your opt-in to an SMS short code campaign with a third party for purposes unrelated to supporting you in connection with that campaign. We may share your Personal Data with third parties that help us provide the messaging service, including, but not limited to, platform providers, phone companies, and other vendors who assist us in the delivery of text messages.</p>
        </section>

        <section>
          <h2>Additional Disclosures</h2>
          <p><strong>Affiliates:</strong> We may disclose the Personal Data to our affiliates or subsidiaries; however, if we do so, their use and disclosure of your Personal Data will be subject to this Policy.</p>
          <p><em>All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</em></p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;