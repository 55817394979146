import '../styles/App.css';

function Home() {
  return (
    <div className="App">
      <div className="App-header">
        <svg className="background-effect" 
          viewBox="0 0 100 100" 
          preserveAspectRatio="xMidYMid slice"
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            filter: 'blur(100px)',
            opacity: 0.7,
          }}>
          <defs>
            <linearGradient id="lavaGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#2E5266">
                <animate 
                  attributeName="stop-color" 
                  values="#2E5266; #6E8898; #9FB1BC; #D3D0CB; #2E5266" 
                  dur="15s" 
                  repeatCount="indefinite"/>
              </stop>
              <stop offset="100%" stopColor="#6E8898">
                <animate 
                  attributeName="stop-color" 
                  values="#6E8898; #9FB1BC; #D3D0CB; #2E5266; #6E8898" 
                  dur="15s" 
                  repeatCount="indefinite"/>
              </stop>
            </linearGradient>
          </defs>

          {/* Adjusted circles to stay within the view */}
          <circle cx="30%" cy="30%" r="25" fill="url(#lavaGradient)">
            <animate attributeName="cx" values="30%;70%;30%" dur="20s" repeatCount="indefinite" />
            <animate attributeName="cy" values="30%;70%;30%" dur="23s" repeatCount="indefinite" />
          </circle>
          <circle cx="70%" cy="70%" r="20" fill="url(#lavaGradient)">
            <animate attributeName="cx" values="70%;30%;70%" dur="23s" repeatCount="indefinite" />
            <animate attributeName="cy" values="70%;30%;70%" dur="20s" repeatCount="indefinite" />
          </circle>
          <circle cx="50%" cy="50%" r="15" fill="url(#lavaGradient)">
            <animate attributeName="cx" values="50%;20%;80%;50%" dur="25s" repeatCount="indefinite" />
            <animate attributeName="cy" values="50%;80%;20%;50%" dur="22s" repeatCount="indefinite" />
          </circle>
        </svg>

        <div className="brand-text">
          <h1>Ex Nihilo</h1>
          <div className="locations">NYC | Miami | Boston | SF</div>
          <a 
            href="https://login.app.carta.com/credentials/login/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="login-link"
          >
            Investor Login
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;

